import '../styles/index.scss';

import React, { useEffect, useState } from 'react';
import { document, window } from 'browser-monads';
import { useDrag, useWheel } from 'react-use-gesture';
import { ReactFitty } from 'react-fitty';

import Div100vh from 'react-div-100vh';
import { GoMute } from 'react-icons/go';
import Helmet from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import { graphql } from 'gatsby';
import { motion } from 'framer-motion';
import NewsletterPopup from '../components/NewsletterPopup/NewsletterPopup.jsx';
import { clickBuyButton, createProduct } from '../utils/shopify-client.js';

const IndexPage = ({ data }) => {
  const dataShop = data.allContentfulBackofficeShop.nodes[0];
  const [showText, setShowtext] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [videoUrl, setVideoUrl] = useState(dataShop.videoDesktop.file.url);
  const [musicPlaying, setMusicPlaying] = useState(false);
  const [newsletterPopup, setNewsletterPopup] = useState(false);
  const isVariants = dataShop.isVariants && dataShop.statut === 'ADD TO CART';

  //CRÉE LE COMPOSANT SHOPIFY
  useEffect(() => {
    if (dataShop.statut === 'ADD TO CART') {
      createProduct(dataShop.bigwaxId);
    }

    if (!isVariants || dataShop.statut !== 'ADD TO CART') {
      const variantsButton = document.getElementById('product-component');
      variantsButton.style.height = '0px';
    }
  }, [dataShop.bigwaxId]);

  //useDrag => Détecte le scroll sur mobile
  //useWheel => Détecte le scroll sur desktop
  const doIShowText = (delta) => {
    if (delta > 7) {
      setShowtext(false);
    }
    if (delta < -7) {
      setShowtext(true);
    }
  };

  // const bindDrag = useDrag((e) => doIShowText(-e.delta[1]), {});
  const bindWheel = useWheel((e) => doIShowText(e.delta[1]), {});

  //DÉTECTE ET ENREGISTRE LA LARGEUR DE LA FENÊTRE
  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    return () =>
      window.removeEventListener('resize', () =>
        setWindowWidth(window.innerWidth)
      );
  }, []);

  //Change la source de la vidéo selon la largeur de l'écran
  useEffect(() => {
    windowWidth < 800
      ? setVideoUrl(dataShop.videoMobile.file.url)
      : setVideoUrl(dataShop.videoDesktop.file.url);
  }, [
    windowWidth,
    dataShop.videoMobile.file.url,
    dataShop.videoDesktop.file.url,
  ]);

  const handleClickCta = (e) => {
    if (dataShop.preorderButtonLink) {
      window.open(dataShop.preorderButtonLink, '_blank');
      return;
    }
    if (!showText) {
      setShowtext(true);
    }
    if (
      showText &&
      typeof window !== 'undefined' &&
      dataShop.statut === 'ADD TO CART'
    ) {
      e.stopPropagation();
      clickBuyButton();
    }
    if (
      showText &&
      typeof window !== 'undefined' &&
      dataShop.statut === 'NEWSLETTER'
    ) {
      if (newsletterPopup) {
        setNewsletterPopup(false);
      } else {
        setNewsletterPopup(true);
      }
    }
  };

  return (
    <>
      <Helmet title={'Étienne de Crécy'} htmlAttributes={{ lang: 'fr' }}>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* MAILJET */}
        <script
          type='text/javascript'
          src='https://app.mailjet.com/pas-nc-pop-in-v1.js'
        ></script>
        {/* MAILJET */}
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-T2M7T7TBNX'
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-T2M7T7TBNX');
          `}
        </script>
      </Helmet>
      {dataShop.mp3 && (
        <ReactPlayer
          style={{ display: 'none' }}
          playing={musicPlaying}
          loop={true}
          url={dataShop.mp3.file.url}
        />
      )}
      <div
        className='music-toggle'
        onClick={() => {
          setMusicPlaying(!musicPlaying);
        }}
      >
        {musicPlaying ? (
          <i className='gg-loadbar-sound' style={{ color: 'white' }}></i>
        ) : (
          <GoMute fill='white' />
        )}
      </div>
      <NewsletterPopup
        visible={newsletterPopup}
        onClose={(e) => {
          handleClickCta(e);
        }}
      />
      <Div100vh
        // {...bindDrag()}
        {...bindWheel()}
        className={showText ? 'main-container cursorPointer' : 'main-container'}
      >
        {/* Filter */}
        <div className='filter' onClick={handleClickCta}></div>
        {/* -------VIDEO */}
        <div className='videoContainer'>
          <video
            muted={!dataShop.mp3 && musicPlaying ? false : true}
            loop
            autoPlay
            playsInline
            key={videoUrl}
          >
            <source src={videoUrl} type='video/mp4' />
            <track kind='captions' />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* -------VIDEO */}
        {/* -------TEXTES */}
        <motion.span
          className='homeTextSpan'
          initial={{ opacity: 1 }}
          animate={{ opacity: showText ? 1 : 0 }}
        >
          <h1 className='punchline'>
            <ReactFitty
              className='punchlineFitty'
              maxSize={260}
              key={windowWidth + 'punchline'}
            >
              {dataShop.punchline}
            </ReactFitty>
          </h1>
          <h2 className='description'>
            <ReactMarkdown
              source={dataShop.description.description}
              className='reactMarkDown'
            />
          </h2>
          <div id='product-component'></div>
          <h1
            className='callToAction'
            style={{
              transform: isVariants ? 'translateY(-60px)' : 'translateY(0px)',
            }}
            onClick={(e) => {
              handleClickCta(e);
            }}
          >
            <ReactFitty
              className='callToActionFitty'
              key={windowWidth + 'cta'}
              maxSize={50}
            >
              {dataShop.callToAction}
            </ReactFitty>
          </h1>
          {dataShop.tourButtonLink && (
            <h1
              className='callToAction'
              style={{
                transform: isVariants ? 'translateY(-60px)' : 'translateY(0px)',
              }}
            >
              <a
                href={dataShop.tourButtonLink}
                style={{ textDecoration: 'none', color: 'white' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <ReactFitty
                  className='callToActionFitty'
                  key={windowWidth + 'cta'}
                  maxSize={50}
                >
                  TOUR
                </ReactFitty>
              </a>
            </h1>
          )}
          {dataShop.preorderButtonLink && (
            <h1
              className='callToAction'
              style={{
                transform: isVariants ? 'translateY(-60px)' : 'translateY(0px)',
              }}
            >
              <a
                href={dataShop.preorderButtonLink}
                style={{ textDecoration: 'none', color: 'white' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <ReactFitty
                  className='callToActionFitty'
                  key={windowWidth + 'cta'}
                  maxSize={50}
                >
                  PREORDER
                </ReactFitty>
              </a>
            </h1>
          )}
        </motion.span>
        {/* -------TEXTES */}
      </Div100vh>
    </>
  );
};

export const query = graphql`
  query DeCrecyShopQuery {
    allContentfulBackofficeShop {
      nodes {
        punchline
        callToAction
        statut
        isVariants
        titre
        bigwaxId
        tourButtonLink
        preorderButtonLink
        description {
          description
        }
        videoMobile {
          file {
            url
          }
        }
        videoDesktop {
          file {
            url
          }
        }
        mp3 {
          file {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
