import React from 'react';

const MailchimpForm = () => {
  return (
    <div id='mc_embed_shell'>
      <link
        rel='stylesheet'
        href='//cdn-images.mailchimp.com/embedcode/classic-061523.css'
        type='text/css'
      />
      <style>
        {`
          #mc_embed_signup {
            background: #fff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 400px;
          }

          #mc-embedded-subscribe-form input[type=checkbox] {
            display: inline;
            width: auto;
            margin-right: 10px;
          }

          #mergeRow-gdpr {
            margin-top: 20px;
          }

          #mergeRow-gdpr fieldset label {
            font-weight: normal;
          }

          #mc-embedded-subscribe-form .mc_fieldset {
            border: none;
            min-height: 0px;
            padding-bottom: 0px;
          }
        `}
      </style>

      <div id='mc_embed_signup'>
        <form
          action='https://etiennedecrecy.us9.list-manage.com/subscribe/post?u=6f27b8fdd7ea09728c7388cb9&amp;id=5d5b578392&amp;v_id=4653&amp;f_id=008cf2e3f0'
          method='post'
          id='mc-embedded-subscribe-form'
          name='mc-embedded-subscribe-form'
          target='_self'
          noValidate
        >
          <div id='mc_embed_signup_scroll'>
            <h2>Subscribe</h2>
            <div className='indicates-required'>
              <span className='asterisk'>*</span> indicates required
            </div>
            <div className='mc-field-group'>
              <label htmlFor='mce-EMAIL'>
                Email Address <span className='asterisk'>*</span>
              </label>
              <input
                type='email'
                name='EMAIL'
                className='required email'
                id='mce-EMAIL'
                required
              />
            </div>
            <div
              id='mergeRow-gdpr'
              className='mergeRow gdpr-mergeRow content__gdprBlock mc-field-group'
            >
              <div className='content__gdpr'>
                <label>Marketing Permissions</label>
                <p>
                  PIXADELIC will use the information you provide on this form to
                  be in touch with you and to provide updates and marketing.
                  Please let us know all the ways you would like to hear from
                  us:
                </p>
                <fieldset
                  className='mc_fieldset gdprRequired mc-field-group'
                  name='interestgroup_field'
                >
                  <label className='checkbox subfield' htmlFor='gdpr_6821'>
                    <input
                      type='checkbox'
                      id='gdpr_6821'
                      name='gdpr[6821]'
                      value='Y'
                    />
                    <span>Email</span>
                  </label>
                  <label className='checkbox subfield' htmlFor='gdpr_6825'>
                    <input
                      type='checkbox'
                      id='gdpr_6825'
                      name='gdpr[6825]'
                      value='Y'
                    />
                    <span>Direct Mail</span>
                  </label>
                  <label className='checkbox subfield' htmlFor='gdpr_6829'>
                    <input
                      type='checkbox'
                      id='gdpr_6829'
                      name='gdpr[6829]'
                      value='Y'
                    />
                    <span>Customized online advertising</span>
                  </label>
                </fieldset>
                <p>
                  You can change your mind at any time by clicking the
                  unsubscribe link in the footer of any email you receive from
                  us, or by contacting us at contact@etiennedecrecy.fr.
                </p>
              </div>
              <div className='content__gdprLegal'>
                <p>
                  We use Mailchimp as our marketing platform. By clicking below
                  to subscribe, you acknowledge that your information will be
                  transferred to Mailchimp for processing.{' '}
                  <a href='https://mailchimp.com/legal/terms'>Learn more</a>{' '}
                  about Mailchimp's privacy practices.
                </p>
              </div>
            </div>
            <div
              aria-hidden='true'
              style={{ position: 'absolute', left: '-5000px' }}
            >
              <input
                type='text'
                name='b_6f27b8fdd7ea09728c7388cb9_5d5b578392'
                tabIndex='-1'
                value=''
              />
            </div>
            <div className='clear'>
              <input
                type='submit'
                name='subscribe'
                id='mc-embedded-subscribe'
                className='button'
                value='Subscribe'
              />
            </div>
          </div>
        </form>
      </div>
      <script src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                (function($) {
                    window.fnames = new Array(); 
                    window.ftypes = new Array();
                    fnames[0]='EMAIL';ftypes[0]='email';
                    fnames[1]='FNAME';ftypes[1]='text';
                    fnames[2]='LNAME';ftypes[2]='text';
                }(jQuery));
                var $mcj = jQuery.noConflict(true);
                `,
        }}
      />
    </div>
  );
};

export default MailchimpForm;
