import Client from 'shopify-buy';
import { document, window } from 'browser-monads';

export const clientShopify = Client.buildClient({
  storefrontAccessToken: '7098abd2c591d4ad520165df8618b1b7',
  domain: 'bigwax.io',
});

export const createProduct = (id) => {
  let scriptURL =
    'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (
      document.getElementsByTagName('head')[0] ||
      document.getElementsByTagName('body')[0]
    ).appendChild(script);

    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    window.ShopifyBuy.UI.onReady(clientShopify).then(function (ui) {
      ui.createComponent('product', {
        id: id,
        node: document.getElementById('product-component'),
        moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
        options: buyButtonOptions,
      });
    });
  }
};

export const buyButtonOptions = {
  product: {
    iframe: false,
    contents: {
      img: false,
      title: false,
      price: true,
    },
    text: {
      button: 'COMMANDER',
    },
    googleFonts: ['Inter'],
  },
  productSet: {
    styles: {
      products: {
        '@media (min-width: 601px)': {
          'margin-left': '-20px',
        },
      },
    },
  },
  modalProduct: {
    contents: {
      img: false,
      imgWithCarousel: true,
    },
    styles: {
      product: {
        '@media (min-width: 601px)': {
          'max-width': '100%',
          'margin-left': '0px',
          'margin-bottom': '0px',
        },
      },
      button: {
        'font-family': 'Inter, sans-serif',
        ':hover': {
          'background-color': '#000000',
        },
        'background-color': '#000000',
        ':focus': {
          'background-color': '#000000',
        },
        'border-radius': '0px',
        'padding-left': '30px',
        'padding-right': '30px',
      },
      title: {
        'font-family': 'Helvetica Neue, sans-serif',
        'font-weight': 'normal',
        'font-size': '28px',
        color: '#000000',
      },
      price: {
        'font-family': 'Helvetica Neue, sans-serif',
        'font-weight': 'normal',
        'font-size': '24px',
        color: '#000000',
      },
      compareAt: {
        'font-family': 'Helvetica Neue, sans-serif',
        'font-weight': 'normal',
        'font-size': '20.4px',
        color: '#000000',
      },
      unitPrice: {
        'font-family': 'Helvetica Neue, sans-serif',
        'font-weight': 'normal',
        'font-size': '20.4px',
        color: '#000000',
      },
      description: {
        'font-family': 'Helvetica Neue, sans-serif',
        'font-weight': 'normal',
        'font-size': '16px',
        color: '#000000',
      },
    },
    googleFonts: ['Inter'],
    text: {
      button: 'Commander',
    },
  },
  modal: {
    styles: {
      modal: {
        'background-color': '#fffdfd',
      },
    },
  },
  option: {
    styles: {
      label: {
        'font-family': 'Inter, sans-serif',
        color: '#000000',
      },
      select: {
        'font-family': 'Inter, sans-serif',
      },
    },
    googleFonts: ['Inter'],
  },
  cart: {
    styles: {
      cart: {
        background: '#FFF',
      },
      footer: {
        background: '#FFF',
      },
      button: {
        color: '#FFF',
        'font-family': 'Inter, sans-serif',
        'font-weight': 'bold',
        ':hover': {
          'background-color': '#000000',
        },
        'background-color': '#000000',
        ':focus': {
          'background-color': '#000000',
        },
        'border-radius': '8px',
      },
      title: {
        color: '#000000',
        'font-size': '22px',
        // 'font-family': 'Inter, sans-serif',
        'font-weight': 'bold',
      },
      header: {
        color: '#000000',
      },
      lineItems: {
        color: '#000000',
        'font-size': '22px',
        // 'font-family': 'Inter, sans-serif',
        'font-weight': 'bold',
      },
      subtotalText: {
        color: '#000000',
      },
      subtotal: {
        color: '#000000',
      },
      notice: {
        color: '#000000',
        'font-size': '22px',
        'font-family': 'Inter, sans-serif',
        'font-weight': 'bold',
      },
      currency: {
        color: '#000000',
      },
      close: {
        color: '#000000',
        ':hover': {
          color: '#000000',
        },
      },
      empty: {
        color: '#000000',
      },
      noteDescription: {
        color: '#000000',
      },
      discountText: {
        color: '#000000',
      },
      discountIcon: {
        fill: '#000000',
      },
      discountAmount: {
        color: '#000000',
      },
    },
    text: {
      title: 'Votre panier',
      total: 'Total',
      notice: '',
      button: 'PAYER',
    },
    contents: {
      note: false,
    },
    popup: true,
    googleFonts: ['Inter'],
  },
  toggle: {
    styles: {
      toggle: {
        'font-family': 'Inter, sans-serif',
        'background-color': '#000000',
        ':hover': {
          'background-color': '#000000',
        },
        ':focus': {
          'background-color': '#000000',
        },
      },
    },
    googleFonts: ['Inter'],
  },
  lineItem: {
    styles: {
      variantTitle: {
        color: '#000000',
      },
      title: {
        color: '#000000',
      },
      price: {
        color: '#000000',
      },
      fullPrice: {
        color: '#000000',
      },
      discount: {
        color: '#000000',
      },
      discountIcon: {
        fill: '#000000',
      },
      quantity: {
        color: '#000000',
      },
      quantityIncrement: {
        color: '#000000',
        'border-color': '#000000',
      },
      quantityDecrement: {
        color: '#000000',
        'border-color': '#000000',
      },
      quantityInput: {
        color: '#000000',
        'border-color': '#000000',
      },
    },
  },
};

export const clickBuyButton = () => {
  // console.log('adding ', buyButtonId, ' to cart')
  // console.log(document.getElementById(buyButtonId))
  const buyButton = document.querySelector('.shopify-buy__btn');
  buyButton.click();
};

export const destroyProduct = (id) => {
  const elem = document.getElementById('product-component-' + id);
  console.log('destroying ', 'product-component-' + id);
  console.log({ elem });
  // destroy all child elements of elem
  // while (elem?.firstChild) {
  //   elem.removeChild(elem.firstChild)
  // }
  // destroy elem
  if (elem) elem.remove();
  console.log('destroyed');
};
